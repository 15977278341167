import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();

	return (
		<Fragment>
			<footer className="pt-7 pt-lg-8 pt-xl-10 pb-18 pb-lg-20 pb-xl-24 bg-medium-gray position-relative">
				<div className="container mb-24" style={{marginBottom: "50px"}}>
					<div className="row">

						<div className="col-sm-6 col-lg-3 col-xl-4 mb-4 mb-sm-5 mb-lg-0">
							<img alt="footer-logo" src="/img/BotScheduling-Logo-Footer.png" className="mb-4" />
								<p className="mb-0 text-dark display-29">An intelligent online scheduling solution.</p>
						</div>

						<div className="col-sm-6 col-lg-3 col-xl-2 mb-4 mb-sm-5 mb-lg-0">
							<h3 className="h5 mb-3 mb-lg-4 normal">Services</h3>
							<ul className="list-unstyled p-0 m-0">
								<li className="pb-2"><Link to={"/product"}>Product Tour</Link></li>
							</ul>
						</div>

						<div className="col-sm-6 col-lg-3 mb-4 mb-sm-0">
							<div className="pl-0 pl-xl-5">
								<h3 className="h5 mb-3 mb-lg-4 normal">Quick link</h3>
								<ul className="list-unstyled p-0 m-0">
									<li className="pb-2"><a href="https://help.botscheduling.com">Help Center</a></li>
									<li className="pb-2"><a href="https://botscheduling.docs.stoplight.io">Developers</a></li>
								</ul>
							</div>
						</div>

						<div className="col-sm-6 col-lg-3">
							<h3 className="h5 mb-3 mb-lg-4 normal">Contact</h3>
							<ul className="list-unstyled p-0 m-0 text-dark">
								<li className="pb-2">
									<div className="d-flex">
										<div className="mr-2">
											<i className="ti-mobile text-primary"></i>
										</div>
										<div>
											<span>602 644 1425</span>
										</div>
									</div>
								</li>
								<li className="py-2">
									<div className="d-flex">
										<div className="mr-2">
											<i className="ti-email text-primary"></i>
										</div>
										<div>
											<span>hello@objectivebits.com</span>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="footer-bg-img"></div>
			</footer>

			<div className="footer-bar xs-font-size13">
				<div className="container">
					<div className="row">
						<div className="col-xs-5 text-left xs-text-center xs-margin-5px-bottom">
							<p>&copy; {year} BotScheduling. All rights reserved.</p>
						</div>
						<div className="col-xs-5 offset-6 text-right xs-text-center">
							<p><Link to={"/sign-in"}>Sign In</Link></p>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Footer;