import React, { Fragment } from 'react';
import Header from './fragments/Header'
import Footer from './fragments/Footer'

const Product = () => {
	return (
		<Fragment>
			<Header />

			<section className="md bg-grey">
				<div className="container lg-container">
					<div className="section-title text-center mb-5 mb-lg-7 wow fadeInDown" data-wow-delay=".2s">
						<h2 className="font-weight-700 mb-3">Awesome Features</h2>
						<p className="w-95 w-md-80 w-lg-60 w-xl-45 mx-auto">You need offer an experience that is not available elsewhere. Clean and creative HTMl template.</p>
					</div>
					<div className="row">
						<div className="col-lg-4 mb-3 mb-lg-0 wow fadeInUp" data-wow-delay=".2s">
							<div className="card card-style04">
								<div className="card-body">
									<div className="icon-box5 w-80px display-flex position-relative overflow-hidden bg-theme-90">
										<i className="ti-blackboard text-white"></i>
									</div>
									<h3 className="display-24 display-xl-21 font-weight-700 mb-1-6 mb-xl-2-0"><span className="font-weight-300">Responsive </span>Layout</h3>
									<p className="mb-0 w-95 w-md-75 w-lg-95 w-xl-80 mx-auto">we run a successful remote for ready to use content for startups business technology or agency</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 mb-3 mb-lg-0 wow fadeInUp" data-wow-delay=".4s">
							<div className="card card-style04">
								<div className="icon-box5 w-80px display-flex position-relative overflow-hidden bg-theme-90">
									<i className="ti-layers text-white"></i>
								</div>
								<h3 className="display-24 display-xl-21 font-weight-700 mb-1-6 mb-xl-2-0">Worldwide <span className="font-weight-300">Support</span></h3>
								<p className="mb-0 w-95 w-md-75 w-lg-95 w-xl-80 mx-auto">we run a successful remote for ready to use content for startups business technology or agency</p>
							</div>
						</div>
						<div className="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
							<div className="card card-style04">
								<div className="icon-box5 w-80px display-flex position-relative overflow-hidden bg-theme-90">
									<i className="ti-pencil-alt text-white"></i>
								</div>
								<h3 className="display-24 display-xl-21 font-weight-700 mb-1-6 mb-xl-2-0">Design <span className="font-weight-300">Strategy</span></h3>
								<p className="mb-0 w-95 w-md-75 w-lg-95 w-xl-80 mx-auto">we run a successful remote for ready to use content for startups business technology or agency</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="md">
				<div className="container lg-container">

					<div className="section-title text-center mb-5 mb-lg-7 wow fadeInDown" data-wow-delay=".2s">
						<h2 className="font-weight-700 mb-3">You deserve better business</h2>
						<p className="w-95 w-md-80 w-lg-60 w-xl-45 mx-auto">You need offer an experience that is not available elsewhere. Clean and creative HTMl template.</p>
					</div>

					<div className="row align-items-center mb-5">
						<div className="col-lg-6 col-md-12 mb-4 mb-lg-0 text-center wow fadeInLeft" data-wow-delay=".1s">
							<img src="img/content/about5-01.png" alt="..." />
						</div>
						<div className="col-lg-6 col-md-12 wow fadeInRight" data-wow-delay=".1s">

							<div className="pl-0 pl-lg-5">
								<h2 className="font-weight-700 mb-4 line-height-normal"><span className="font-weight-300">Get tips & tricks on quickly </span> query and receive credit interface</h2>
								<p className="mb-2-3 small-title w-85">Our passion to work hard and deliver excellent results. It could solve your customers and develop innovation.</p>
								<ul className="list-style7">
									<li><i className="fas fa-check"></i>Exclusive design</li>
									<li><i className="fas fa-check"></i>We Provide Awesome Services</li>
									<li><i className="fas fa-check"></i>Your business deserves best design</li>
								</ul>
							</div>

						</div>
					</div>
					<div className="row align-items-center mb-5">

						<div className="col-lg-6 col-md-12 wow fadeInLeft order-2 order-lg-1" data-wow-delay=".1s">

							<div className="pr-0 pr-lg-5">

								<h2 className="font-weight-700 mb-4 line-height-normal"><span className="font-weight-300">Powerful services for</span> your great and valuable customers</h2>

								<p className="mb-2-3 small-title w-85">Our passion to work hard and deliver excellent results. It could solve your customers and develop innovation.</p>
								<ul className="list-style7">
									<li><i className="fas fa-check"></i>Simple and smart HTML code</li>
									<li><i className="fas fa-check"></i>Solve your problem with excellent results</li>
									<li><i className="fas fa-check"></i>Your trusted partner for working together for you</li>
								</ul>

							</div>

						</div>
						<div className="col-lg-6 col-md-12 text-center mb-4 mb-lg-0 order-1 order-lg-2 wow fadeInRight" data-wow-delay=".1s">
							<img src="img/content/about5-02.png" alt="..." />
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 mb-4 mb-lg-0 wow fadeInLeft text-center" data-wow-delay=".1s">
							<img src="img/content/about5-03.png" alt="..." />
						</div>
						<div className="col-lg-6 col-md-12 wow fadeInRight" data-wow-delay=".1s">

							<div className="pl-0 pl-lg-5">
								<h2 className="font-weight-700 mb-4 line-height-normal"><span className="font-weight-300"> Send targeted messages </span> to the right people of your agency</h2>

								<p className="mb-2-3 small-title w-85">Our passion to work hard and deliver excellent results. It could solve your customers and develop innovation.</p>
								<ul className="list-style7">
									<li><i className="fas fa-check"></i>Quick & easy process</li>
									<li><i className="fas fa-check"></i>Your business deserves best</li>
									<li><i className="fas fa-check"></i>The elements from one design to another</li>
								</ul>

							</div>

						</div>
					</div>
				</div>
			</section>

			<Footer />
		</Fragment>
	)
}

export default Product;