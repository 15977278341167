import React from 'react';
import {Link} from "react-router-dom";

const SignIn = () => {
	return (
		<section className="bg-light p-0">
			<div className="container d-flex flex-column min-vh-100">

				<div className="row align-items-center justify-content-center pt-12">
					<div className="col-sm-11 col-md-8 col-lg-7 col-xl-5">

						<div className="card p-2-0 p-sm-2-5 my-2-0">

							<div className="mb-4 text-center">
								<h2 className="font-weight-normal mb-0">BotScheduling</h2>
								<p className="mb-0">Log In</p>
							</div>

							<form>

								<div className="row">

									<div className="col-12">

										<div className="form-group">
											<label>Email</label>
											<input type="text" className="form-control" name="email"
												   placeholder="Your email" />
										</div>

									</div>

									<div className="col-12">

										<div className="form-group">
											<label>Password</label>
											<input type="password" className="form-control" name="password"
												   placeholder="Your password" />
										</div>

									</div>

								</div>

								<div className="row mt-2">
									<div className="col-sm-6 mb-2 mb-sm-0">
										<div className="custom-control custom-checkbox">
											<input type="checkbox" className="custom-control-input" id="login-remember" />
												<label className="custom-control-label display-30"
													   htmlFor="login-remember">Keep me signed in</label>
										</div>
									</div>
									<div className="col-sm-6 text-left text-sm-right">
										<a href="password-recovery.html" className="m-link-muted small">Forgot
											password?</a>
									</div>

									<div className="col-md-12 center">
										<button type="submit"
												className="butn style-one fill btn-block mt-4 mb-0">Login
										</button>
									</div>
								</div>

								<div className="text-center text-small mt-4">
									<span>Don't have an account yet? <a href="register.html"
																		className="text-primary">Register</a></span>
								</div>

							</form>

						</div>

					</div>
				</div>
				<div className="row align-items-center justify-content-center">
					<div className="col-sm-11 col-md-8 col-lg-7 col-xl-5">
						<Link to={"/"}><i className="fa fa-arrow-left"></i> Back to home</Link>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SignIn;