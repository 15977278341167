import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const Header = () => {
	return (
		<Fragment>
			<header>
				<div className="navbar-default">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12">
								<div className="menu_area alt-font">
									<nav className="navbar navbar-expand-lg navbar-light p-0">
										<div className="navbar-header navbar-header-custom">
											<Link to={"/"} className="navbar-brand">
												<img id="logo" src="img/BotScheduling-Logo-old.png" alt="logo" />
											</Link>
										</div>

										<div className="navbar-toggler dark" />

										<ul className="navbar-nav ml-auto" id="nav">
											<li><Link to={"/product"}>Product Tour</Link></li>
											<li><Link to={"/pricing"}>Pricing</Link></li>
											<li><Link to={"/sign-in"}>Sign In</Link></li>
											<li>
												<Link to="/account/create" className="butn create-account">
													Create Account
												</Link>
												{/*<Link to={"/sign-up"} className="btn btn-primary text-white"*/}
												{/*   style={{marginTop: 22, paddingTop: 0, paddingBottom: 0}}>Start FREE</Link>*/}
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</Fragment>
	)
}

export default Header;