import React from 'react';
import {Link} from "react-router-dom";

const Create = () => {
	return (
		<section className="p-0">
			<div className="container-fluid d-flex flex-column">
				<div className="row align-items-center justify-content-center min-vh-100">

					<div className="col-md-8 col-lg-6 col-xl-5 text-center text-lg-left mb-4 mb-lg-0">

						<div className="pl-xl-5">

							<div className="mb-2-2">
								<h1 className="mb-3">We're coming soon.</h1>
								<p className="lead w-95 w-md-100 w-xl-75 mx-auto mx-lg-0">We are currently operating in a closed beta. Contact us in your are interested in testing.</p>
								<Link to={"/"}><i className="fa fa-arrow-left"></i> Back to home</Link>
							</div>

						</div>
					</div>

				</div>
			</div>
		</section>
	)
}

export default Create;