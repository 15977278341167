import React, { Fragment } from 'react';
import Header from './fragments/Header'
import Footer from './fragments/Footer'
import {Link} from "react-router-dom";

export const Home = () => {
    return (
        <Fragment>
            <Header />

            <div className="main-banner-style02 cover-background header-position">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-text w-md-75 w-lg-100 pb-5 pb-lg-0">
                                <h1 className="wow fadeInUp text-primary mb-4" data-wow-delay=".1s">
                                    An <strong className="font-weight-600">intelligent</strong> online scheduling solution
                                </h1>
                                <p className="mb-lg-2-0 w-90 w-md-80 wow fadeInUp" data-wow-delay=".2s">
                                    Allow your customers to book appointments with you anytime, anywhere. Our bots are busy scheduling customer appointments even when you are sleeping.
                                </p>
                                <div className="wow fadeInUp" data-wow-delay=".4s">
                                    <Link to={"/account/create"} className="butn mr-2 align-middle">TRY IT NOW</Link>
                                    {/*<Link to="/account/create" className="butn create-account">*/}
                                    {/*   Create Account*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>

                        {/*todo This part should disappear on small screens*/}
                        <div className="col-lg-6 text-center text-lg-right">
                            <div className="banner-img">
                                <img src="img/Robot-Scheduling.png" alt="robot-scheduling" style={{ marginTop: "100px" }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-shape d-none d-sm-block">
                    <img src="img/banner/header-bg2.png" className="img-fluid w-100" alt="header-background" />
                </div>
            </div>

            <section>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-5 mb-lg-0 hover-style3 text-center text-lg-left">
                            <div className="px-3 px-md-5 px-lg-0">
                                <img src="img/Busy-Bots.png" alt="..." />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="pl-lg-5">

                                <h2 className="mb-3">What the Bots do for you</h2>
                                <p className="display-28 mb-2-2">Entegration aute irure design in reprehenderit in
                                    voluptate eu fugiat nulla pariatur.</p>

                                <div className="media align-items-top mb-2-8">
                                    <i className="ti-announcement text-danger mr-1-6 mt-n1 display-18"></i>
                                    <div className="media-body mt-n1">
                                        <h3 className="h5">Scheduling for the future</h3>
                                        <p className="mb-0">Track detailed notes, appointment spliting and system recommendations to fill your schedule.</p>
                                    </div>
                                </div>

                                <div className="media align-items-top mb-5">
                                    <i className="ti-email text-info mr-1-6 mt-n1 display-18"></i>
                                    <div className="media-body mt-n1">
                                        <h3 className="h5">Automatic notifications</h3>
                                        <p className="mb-0">Provide human-readable notifications to your customers reminding them of their upcoming appointments or allowing them to reschedule.</p>
                                    </div>
                                </div>

                                <div className="media align-items-top mb-5">
                                    <i className="ti-calendar text-info mr-1-6 mt-n1 display-18"></i>
                                    <div className="media-body mt-n1">
                                        <h3 className="h5">Employee scheduling</h3>
                                        <p className="mb-0">Every employee has their own calendar and can be combined to view openings at a glance.</p>
                                    </div>
                                </div>

                                <a href="#!" className="butn style-one fill">Get Started</a>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-grey">
                <div className="container">
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-lg-7 col-xl-6">
                            <h2>Help is only <strong className="text-highlight font-weight-700">one step</strong> away.</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="horizontaltab tab-style02" style={{display: "block", width: "100%", margin: "0px"}}>
                        <div className="resp-tabs-container hor_1">
                            <div className="first resp-tab-content hor_1 resp-tab-content-active"
                                 aria-labelledby="hor_1_tab_item-0" style={{display:"block"}}>
                                <div className="row justify-content-center">
                                    <div className="col-lg-11">
                                        <div className="row align-items-center">
                                            <div className="col-lg-5 order-2 order-lg-1">
                                                <div className="tab-box rounded">
                                                    <h3 className="mb-1-6">Find answers related to our products and services.</h3>
                                                    {/*<p className="display-30">*/}
                                                    {/*    Looking for answers? Search our Help Center.*/}
                                                    {/*</p>*/}
                                                    <a href="#!" className="butn style-two">Help Center</a>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 order-1 order-lg-2 mb-4 mb-lg-0">
                                                <div className="tab-img">
                                                    <img src="img/Help-Center.png" className="rounded" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </Fragment>
    )
}

export default Home;