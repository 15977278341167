import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import Home from './components/pages/Home'
import Product from './components/pages/Product'
import Pricing from './components/pages/Pricing'
import SignIn from './components/pages/SignIn'
import Create from './components/pages/Create'
import './App.css';

const App = () => {
    const [things, setThings] = useState([]);
    const [loading, setLoading] = useState(false);

    const getThings = async things => {
        setLoading(true);

        const res = await axios.get(`${process.env.REACT_APP_URL}/things`);

        setThings(res.data);
        setLoading(false);
    };

    useEffect(() => {
        getThings();
    }, []);

    return (
        <Router>
            <div className="main-wrapper">
                <Switch>
                    <Route exact path='/props' render={props => (
                        <Fragment>home</Fragment>
                    )}/>
                    <Route exact path='/' component={ Home } />
                    <Route exact path='/product' component={ Product } />
                    <Route exact path='/pricing' component={ Pricing } />
                    <Route exact path='/sign-in' component={ SignIn } />
                    <Route exact path='/account/create' component={ Create } />
                </Switch>
            </div>
        </Router>
    );
}

App.propTypes = {
    things: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
}

export default App;
