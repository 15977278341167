import React, { Fragment } from 'react';
import Header from './fragments/Header'
import Footer from './fragments/Footer'

const Pricing = () => {
	return (
		<Fragment>
			<Header />

			<section class="md bg-grey">
				<div class="container lg-container">
					<div class="section-title text-center mb-5 mb-lg-7 wow fadeInDown" data-wow-delay=".2s">
						<h2 class="font-weight-700 mb-3">Simple Pricing for Your Team</h2>
						<p class="w-95 w-md-80 w-lg-60 w-xl-45 mx-auto">You need offer an experience that is not available elsewhere. Clean and creative HTMl template.</p>
					</div>
				</div>

				<div class="container">

					<div class="row price price-style1">

						<div class="col-lg-4 mb-5 mb-lg-0 wow fadeInUp" data-wow-delay=".2s">
							<div class="item text-center">
								<div class="type">
									<h4 class="mb-2-2">Basic</h4>
								</div>
								<div class="value mb-3">
									<h3>10<span>$</span></h3>
									<span class="per"> / Per Month</span>
								</div>
								<div class="features">
									<ul>
										<li>24/7 Tech Support</li>
										<li>Advanced Options</li>
										<li>1GB Storage</li>
									</ul>
								</div>
								<div class="order">
									<a href="#!" class="butn style-two reverse">Purchase Now</a>
								</div>
							</div>
						</div>

						<div class="col-lg-4 mb-5 mb-lg-0 wow fadeInUp" data-wow-delay=".4s">
							<div class="item text-center active bg-theme-90">
								<div class="type">
									<h4 class="mb-2-2">Standard</h4>
								</div>
								<div class="value mb-3">
									<h3>21<span>$</span></h3>
									<span class="per"> / Per Month</span>
								</div>
								<div class="features">
									<ul>
										<li>24/7 Tech Support</li>
										<li>16GB Bandwidth</li>
										<li>1.5GB Storage</li>
									</ul>
								</div>
								<div class="order">
									<a href="#!" class="butn style-two">Purchase Now</a>
								</div>
							</div>
						</div>

						<div class="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
							<div class="item text-center">
								<div class="type">
									<h4 class="mb-2-2">Premium</h4>
								</div>
								<div class="value mb-3">
									<h3>99<span>$</span></h3>
									<span class="per"> / Per Month</span>
								</div>
								<div class="features">
									<ul>
										<li>24/7 Tech Support</li>
										<li>Additional Features</li>
										<li>6GB Storage</li>
									</ul>
								</div>
								<div class="order">
									<a href="#!" class="butn style-two reverse">Purchase Now</a>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>

			<Footer />
		</Fragment>
	)
}

export default Pricing;